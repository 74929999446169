<template>
  <div :class="`custom-tab  bg-ems-gray800 h-full px-[16px] overflow-hidden`">
    <div class="w-full mt-[10px] h-[92vh] pb-5">
      <InputSearch v-model="userName" :placeholder="t('common.search')" />
      <div
        ref="scrollContainer"
        class="hidden-scroll overflow-x-hidden"
        :style="{ height: `${windowHeight - 280}px` }"
        @scroll="handleScroll"
      >
        <div v-for="(entry, entryIndex) in listDialogs" :key="entryIndex">
          <ItemUser
            :peerId="entry.otherInfo.peerId || ''"
            :onCall="entry.onCall"
            :avt="entry.otherInfo.peerAvatarUrl || ''"
            :id-hover="idHover"
            :id-select="idSelect"
            :peerName="entry.otherInfo.peerName"
            :dialogType="entry.dialogType || 0"
            @handle-select-item-user="handleClick(entry)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { ref, computed, onMounted, watch } from 'vue';
import { useWindowHeight } from '@/util/common-utils';
import _ from 'lodash';
import { debounce } from 'lodash';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { PEER_TYPE, TAB_COORDINATION } from '@/util/common-constant.js';
import InputSearch from '../input-search.vue';
import ItemUser from './item-user.vue';
const route = useRoute();
const { t } = useI18n();
const { state, dispatch } = useStore();
const { windowHeight } = useWindowHeight();
const listDialogs = computed(() => state.coordinator.listDialogs);
const totalElements = computed(() => state.coordinator.totalElements);
const idSelect = ref('');
const idHover = ref('');
const DEBOUNCE_TIME = 500;
const scrollContainer = ref(null);
const page = ref(0);
const initScroll = ref(true);
const userName = ref('');

watch(userName, (newVal) => {
  userName.value = newVal;
  searchUser(newVal);
});
watch(
  () => [route.query.id],
  (newId, oldId) => {
    const item = listDialogs.value.find(
      (item) => item.otherInfo.peerId === newId[0]
    );
    if (item) {
      handleClick(item);
    }
  }
);
onMounted(async () => {
  await fetchData(0, true, userName.value);
});
const handleScroll = () => {
  if (scrollContainer.value) {
    const scrollTop = scrollContainer.value.scrollTop;
    const scrollHeight = scrollContainer.value.scrollHeight;
    const offsetHeight = scrollContainer.value.offsetHeight;
    if (scrollTop + offsetHeight >= scrollHeight - 1) {
      if (page.value <= Math.floor(totalElements.value / 10) + 1) {
        page.value++;
        fetchData(page.value, false, userName.value);
      }
    }
  }
};
const searchUser = debounce((query) => {
  fetchData(page.value, true, query);
}, DEBOUNCE_TIME);
const fetchData = async (page, firstData, query) => {
  dispatch('coordinator/getListDialogs', {
    payload: { page: page, size: 10, q: query },
    firstData: firstData,
  }).then(() => {
    const item = listDialogs.value.find(
      (item) => item.otherInfo.peerId === route.query.id
    );
    if (initScroll.value) {
      if (item) {
        handleClick(item);
      } else {
        handleClick(listDialogs.value[0]);
      }
      initScroll.value = false;
    }
  });
};

const handleSendParams = (item) => {
  let query = {
    id: item.otherInfo.peerId,
    tab:TAB_COORDINATION.COORDINATION
  };
  dispatch('coordinator/handleSetParams', query);
};
const handleGetMembers = async (item) => {
  let participants = await dispatch(
    'coordinator/getParticipants',
    item.otherInfo.peerId
  );
  if (item.otherInfo.peerType === PEER_TYPE.CHANNEL) {
    if (participants) {
      let ids = [];
      for (let key in participants) {
        ids.push(participants[key].userId);
      }
      await dispatch('coordinator/getListStatus', ids.join(','));
    }
  } else {
    await dispatch('coordinator/getListStatus', item.otherInfo.peerId);
  }
};
const handleSendItemValue = (item, participants) => {
  let value = {
    ...item,
    participants: participants,
    tab: TAB_COORDINATION.COORDINATION,
  };
  dispatch('coordinator/selectValue', value);
};

const handleClick = async (item) => {
  idSelect.value = item.otherInfo.peerId;
  handleSendParams(item);
  handleGetMembers(item);
  let payload = {
    roomId: item.dialogId,
  };
  await dispatch('coordinator/getInfoCall', payload).then((data) => {
    handleSendItemValue(item, data.participants);
  });
};
</script>
<style lang="scss">
.custom-tab {
  transition: width 0.5s ease;
}
.custom-tab .ant-tabs-nav-list {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #2B2A3A;
  margin-left: 20px;
  margin-right: 20px;
}
.custom-tab .ant-tabs-tab {
  width: 140px;
  display: flex;
  justify-content: center;
}
</style>
